import React from 'react'
import {Text} from '@chakra-ui/react';

const Footer = () => {
  return (
    <footer id="footer" align="center">
      <Text className='text-white' margin="2px">All rights reserved. Copyright © 2022 Simplys</Text>
    </footer>
  )
}

export default Footer