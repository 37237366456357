import React from 'react';
import {Box, Button, Flex, Image, Spacer} from '@chakra-ui/react';
import Twitter from "../assets/social-media-icons/circle_twitter.png"
import Discord from "../assets/social-media-icons/circle_discord.png"
import { Link } from 'react-router-dom'

const NavBar = ({ accounts, setAccounts}) => {
    const isConnected = Boolean(accounts[0]);

    async function connectAccount() {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
        }
    }

    return (
        <Flex justify="space-between" align="center" padding="2px">
        
            {/* Left Side - Social Media Icons */}
            <Flex justify="space-around" width="10%" padding="0 60px" >
                
                <a href="https://twitter.com/SimplysNFT" target="_blank" rel="noopener noreferrer">
                    <Image src={Twitter} boxSize="42px" margin="0 5px" />
                </a>
                <a href="https://discord.com/invite/TkRYkjuvsm" target="_blank" rel="noopener noreferrer">
                    <Image src={Discord} boxSize="42px" margin="0 5px" />
                </a>
            </Flex>

            {/* Right Side - Social Media Icons */}
            <Flex
                justify="space-around"
                width="40%"
                align="center"
                padding="20px"
            >
                <Link className='links' margin="0 15px" to="/profile">Profile</Link>
                <Spacer />
                <Link className='links' margin="0 15px" to="/">Mint</Link>
                <Spacer />
                <div id="wallet">
                    
                </div>

                {/* Connect*/}
            {isConnected ? (
                <Box margin="0 15px" color="#48bb78">Connected</Box>
            ) : (
                <Button
                    backgroundColor="#48bb78"
                    borderRadius="5px"
                    boxShadow="0px 2px 2px 1px #0F0F0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="15px"
                    margin="0 15px"
                    onClick={connectAccount}
                    >
                        Connect
                </Button>

            )}
            </Flex>

            
        </Flex>
    )
}

export default NavBar;