import React from 'react'
import {Box, Button, Flex, Image} from '@chakra-ui/react';
import {ethers} from 'ethers';
import simplesABI from '../Simples.json';
import simplysV2NFT from '../SimplysV2NFT.json';
import credsLogo from '../assets/images/simplys_token.png'

const simplysV2NFTAddress = "0xa7F8Df3D059cAA6AA5DBEEf7dB5F539F7A51C502";
const simplesAddress = "0xD6c963A0B677501b23529F9e908556fc6305341C";
const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
var PendingBalance;
var CurrentBalance;
var SimplysV2;

const simples = {
    address: simplesAddress,
    abi: simplesABI.abi,
}

const simplys = {
  address: simplysV2NFTAddress,
  abi: simplysV2NFT.abi,
}

async function main() {
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  let userAddress = await signer.getAddress();

  const credsContract = new ethers.Contract(simples.address, simples.abi, signer);
  const simplysContract = new ethers.Contract(simplys.address, simplys.abi, signer);

  let pendingBalance = await credsContract.totalAvailable(userAddress);
  let formattedPendingBalance = ethers.utils.formatUnits(pendingBalance, 18);
  PendingBalance = Math.trunc( formattedPendingBalance );
   

  let currentBalance = await credsContract.balanceOf(userAddress);
  let formattedCurrentBalance = ethers.utils.formatUnits(currentBalance, 18);
  CurrentBalance = Math.trunc( formattedCurrentBalance );

  let simplysOwned = await simplysContract.balanceOf(userAddress);
  let formattedSimplysV2 = ethers.utils.formatUnits(simplysOwned, 0);
  SimplysV2 = Math.trunc( formattedSimplysV2 );
}
main();

async function claim() {
  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
        simplesAddress,
        simplesABI.abi,
        signer
    );
    try {
        const response = await contract.claim({
            value: ethers.utils.parseEther((0).toString()),
        });
        console.log('response: ', response);
        } catch(err) {
            console.log("error: ", err)
          }
  }
}


const Profile = () => {
  return (
    
    <Flex align="center" justify="center" height="100vh" paddingBottom="150px">
            <Box className='right' backgroundColor="#0b0e25" padding="20px" marginLeft="20px">
                <Box>
                    <Image src={credsLogo} width="80px" height="80px" margin="5px" />
                    <p>Pending</p>
                    <p id="pendingBalance">{PendingBalance}</p>
                    <Button 
                        backgroundColor="#f9d44e"
                        borderRadius="5px"
                        boxShadow="0px 2px 2px 1px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        marginTop="10px"                
                        onClick={claim}> Claim
                    </Button>
                </Box>
            </Box>
            <Box className='profile' backgroundColor="#0b0e25" padding="20px" marginLeft="20px">
                <Box>
                    <p>SimplysV2 Owned: {SimplysV2}</p>
                    <p>$Simples Balance: {CurrentBalance}</p>
                </Box>
            </Box>
    </Flex>
  )
}

export default Profile