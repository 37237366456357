import { useState } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Mint from './pages/mint';
import Profile from './pages/profile';
import { Routes, Route} from "react-router-dom";

function App() {
  const [accounts, setAccounts] = useState([]);

  return (
          <div className='overlay'>
            <div className="App">
              <NavBar accounts={accounts} setAccounts={setAccounts} />
              <Routes>
                <Route exact path="/" element={<Mint accounts={accounts} setAccounts={setAccounts} />} />
                <Route exact path="/profile" element={<Profile />} />
              </Routes>
              <Footer />
            </div>
          </div>
  );
}

export default App;
